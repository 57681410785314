import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import {ReactComponent as LogoIcon} from '../icons/logo.svg';
import {ReactComponent as MenuIcon} from '../icons/menu.svg';
import IconButton from '@material-ui/core/IconButton';
import { Link } from "react-router-dom";
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router';
import './menu.css';

const MenuComponent = () => {
    const [openMenu, setOpenMenu] = useState(false)

    const { t } = useTranslation();

    const handleClick = () => {
        setOpenMenu(!openMenu);
    }
    return (
        <div className="iq__menu__content">
            <div className="iq__whatsapp">
                <a href="https://api.whatsapp.com/send?phone=525574764839"> </a>
            </div>
            <div className="iq__menu">
                <Link to="/home" className="iq__menu__logo">
                    <LogoIcon />
                </Link>
                <IconButton className="iq__menu__button" onClick={() => handleClick()}>
                    <MenuIcon className="iq__menu__icon"/>
                </IconButton>
                <div className="iq__menu__sections">
                    <NavLink to="/enterprises">
                        {t("menu_home")}
                    </NavLink>
                    <NavLink to="/about">
                        {t("menu_how")}
                    </NavLink>
                    <NavLink to="/home">
                        {t("menu_about")}
                    </NavLink>
                    <NavLink to="/contact">
                        {t("menu_contact")}
                    </NavLink>
                </div>
            </div>
            { openMenu && 
                <div className="iq__menu__sections__mobile">
                    <NavLink to="/home">
                        {t("menu_home")}
                    </NavLink>
                    <NavLink to="/about">
                        {t("menu_how")}
                    </NavLink>
                    <NavLink to="/enterprises">
                        {t("menu_enterprises")}
                    </NavLink>
                    <NavLink to="/contact">
                        {t("menu_contact")}
                    </NavLink>
                </div>
            }
        </div>
    )
}

export default withRouter(MenuComponent);